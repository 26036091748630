.teamFirstScreen {
    position: relative;
    margin-top: -56px;
}

.teamFirstScreen .content div {
    position: relative;
    z-index: 2;
}

.teamFirstScreen .content {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: space-between;
    gap: 100px;
    padding-top: 200px;
    padding-bottom: 150px;
}

.teamFirstScreen .text {
    max-width: 405px;
}

.teamFirstScreen .text h2 {
    font-size: 32px;
    font-weight: 500;
}

.teamFirstScreen .text p {
    font-size: 18px;
    font-weight: 400;
    margin-top: 24px;
}

.teamFirstScreen .image {
    flex: 1;
}

.teamFirstScreen .image img {
    border-radius: 4px;
    width: 100%;
}

.teamFirstScreen:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100px;
    background: linear-gradient(180deg, rgba(255,255,255,0), rgba(255,255,255,1));
    left: 0;
    bottom: 0;
}

.teamMembers {
    padding-top: 100px;
    padding-bottom: 150px;
}

.teamMembers h3 {
    font-size: 32px;
    font-weight: 500;
    letter-spacing: -1.5px;
    line-height: 1.5;
    margin-bottom: 50px;
}

.teamMember {
    display: flex;
    gap: 100px;
    margin-bottom: 60px;
    position: sticky;
    top: 50px;
    overflow: hidden;
    background: white;
    border-top: 1px solid #2a2a31;
    padding-top: 20px;
}

.teamMember .memberImg {
    width: 310px;
    height: auto;
    object-fit: contain;
    flex: 3;
}

.teamMember .mainInfo {
    flex: 2;
}

.teamMember .name {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 20px;
}
.teamMember .position {
    font-size: 18px;
    font-weight: 500;
}

.teamMember .description {
    flex: 4;
}
.teamMember .description p {
    margin-bottom: 24px;
    font-size: 18px;
    font-weight: 400;
}

.teamMember .free {
    flex: 0;
}
