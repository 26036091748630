* {
  margin: 0;
  padding: 0;
  list-style-type: none;
  box-sizing: border-box;
}

body {
  margin: 0;
  color: #2A2A31;
  font-family: Inter, Avenir, Helvetica, Arial, sans-serif;
  scroll-behavior: smooth;
}

header {
  position: sticky;
  z-index: 999;
  top: 60px;
  width: 100%;
  background: transparent;
  transition: all .5s;
}

header.top {
  top: 10px;
}

.content {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 100px;
}
header .content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

header nav {
  padding: 0 29px;
  background: #FFF;
  border-radius: 999px;
  display: flex;
  gap: 29px;
}

header nav, header a {
  position: relative;
  z-index: 2;
}

header nav a {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-decoration: none;
  color: #2A2A31;
  padding: 19.5px 15px;
  position: relative;
}

header nav a.active:before {
  background: #2A2A31;
  width: 6px;
  height: 6px;
  border-radius: 999px;
  transform: translateY(-50%);
  top: 50%;
  left: 0;
  content: "";
  position: absolute;
}

footer {
  position: absolute;
  bottom: 60px;
  left: 0;
  width: 100%;
  font-size: 12px;
}

footer .content {
  display: flex;
  justify-content: space-between;
}

.defaultBtn {
  padding: 20px 32px;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  color: #2A2A31;
  border-radius: 999px;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0px 26px 10px rgba(85, 159, 195, 0.01), 0px 14px 9px rgba(85, 159, 195, 0.04), 0px 6px 6px rgba(85, 159, 195, 0.06), 0px 2px 4px rgba(85, 159, 195, 0.07);

}

.spline {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
}

main {
  position: relative;
}
