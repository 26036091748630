.home_page {
    /*background: aqua;*/
    width: 100%;
    height: 100vh;
    margin-top: -56px;
}

.home_page .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 280px;
    height: 100%;
}

.home_page .content p {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 30px;
    width: 100%;
    max-width: 420px;
}

.home_page .content * {
    position: relative;
    z-index: 2;
}
